import "./datadog";

import { OpenAPI as VlosAPI } from "@workspace/open-api/value-link-ordering-service";
import { OpenAPI as ScionAPI } from "@workspace/open-api/scion";
import { OpenAPI as CustomerManagementServiceAPI } from "@workspace/open-api/cms";
import { OpenAPI as SavedCardServiceServiceAPI } from "@workspace/open-api/scs";
import { OpenAPI as ApiKeyService } from "@workspace/open-api/api-key-service";
import { OpenAPI as PricingService } from "@workspace/open-api/pricing-service";
import { OpenAPI as PlatformAPIGatewayAPI } from "@workspace/open-api/platform-api-gateway";
import { OpenAPI as WebhookService } from "@workspace/open-api/webhook-service";
import { OpenAPI as RosUiAPI } from "@workspace/open-api/ros-ui";
import { OpenAPI as CancellationUiService } from "@workspace/open-api/cancellation-service";
import { OpenAPI as CustomisationAPI } from "@workspace/open-api/customisation-service";
import { OpenAPI as CatalogService } from "@workspace/open-api/catalog-service";
import { OpenAPI as CatalogServiceV2 } from "@workspace/open-api/catalog-service";
import { OpenAPI as TransactionsApi } from "@workspace/open-api/transactions-service";

import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "@two-ui/router-progress-bar"; // Enable a progress bar on slow routes
import { getJwtToken, getInstance } from "@two-ui/auth0";
import store from "./store";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";
import "./icons";
import { initSentryFromEnv } from "./sentry";
import VueHotjar from "vue-hotjar-next";
import { loadRiskifiedBeacon } from "./riskified-beacon";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import emojiFont from "@two-ui/assets/TwemojiCountryFlags.woff2";
import directivesPermission from "@two-ui/directives/permission";
import { useGlobalStore } from "./global-store";
const pinia = createPinia();

const app = createApp(App);
initSentryFromEnv({ app, router });
app.use(pinia);

store.populateInitialState();

loadRiskifiedBeacon(); // load riskified beacon and generate session_id for it
app.directive("permissions", directivesPermission);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("font-awesome-layers", FontAwesomeLayers);
app.use(router);
const globalStore = useGlobalStore();

// To allow for country flag emojis on Windows
if (polyfillCountryFlagEmojis("TwemojiCountryFlags", emojiFont)) {
  console.debug("Country flag emojis polyfilled");
}

VlosAPI.BASE = import.meta.env.VITE_VLOS_BASE_URL;
VlosAPI.TOKEN = getJwtToken;

ScionAPI.BASE = import.meta.env.VITE_SCION_BASE_URL;
ScionAPI.TOKEN = getJwtToken;

CustomerManagementServiceAPI.BASE = import.meta.env.VITE_CMS_BASE_URL;
CustomerManagementServiceAPI.TOKEN = getJwtToken;

SavedCardServiceServiceAPI.BASE = import.meta.env.VITE_SCS_BASE_URL;
SavedCardServiceServiceAPI.TOKEN = getJwtToken;

ApiKeyService.BASE = import.meta.env.VITE_API_KEY_SERVICE_BASE_URL;
ApiKeyService.TOKEN = getJwtToken;

PricingService.BASE = import.meta.env.VITE_UI_API_GATEWAY_BASE_URL;
PricingService.TOKEN = getJwtToken;

PlatformAPIGatewayAPI.BASE = import.meta.env.VITE_PLATFORM_API_GATEWAY_URL;
PlatformAPIGatewayAPI.TOKEN = getJwtToken;

WebhookService.BASE = import.meta.env.VITE_UI_API_GATEWAY_BASE_URL;
WebhookService.TOKEN = getJwtToken;

RosUiAPI.BASE = import.meta.env.VITE_UI_API_GATEWAY_BASE_URL;
RosUiAPI.TOKEN = getJwtToken;

CancellationUiService.BASE = import.meta.env.VITE_UI_API_GATEWAY_BASE_URL;
CancellationUiService.TOKEN = getJwtToken;

CustomisationAPI.BASE = import.meta.env.VITE_CUSTOMISATION_BASE_URL;
CustomisationAPI.TOKEN = getJwtToken;

CatalogService.BASE = import.meta.env.VITE_UI_API_GATEWAY_BASE_URL;
CatalogService.TOKEN = getJwtToken;

CatalogServiceV2.BASE = import.meta.env.VITE_UI_API_GATEWAY_BASE_URL;
CatalogServiceV2.TOKEN = getJwtToken;

TransactionsApi.BASE = import.meta.env.VITE_UI_API_GATEWAY_BASE_URL;
TransactionsApi.TOKEN = getJwtToken;

app.mount("#app");

window.auth0 = getInstance;
window.store = store;
