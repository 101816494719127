<template>
  <div
    data-testid="modal"
    role="dialog"
    v-if="show"
    @click="doNotClose || closeModal()"
    class="h-100 animated fadeIn faster scrollbar-hide fixed inset-0 z-50 flex w-full overflow-y-scroll bg-black bg-opacity-40 py-2 duration-1000"
  >
    <div
      class="z-50 m-auto mx-auto overflow-y-auto rounded-2xl bg-white shadow-lg tablet:w-[90%]"
      @click.stop
      :class="containerClass"
      data-testid="modal"
    >
      <div
        class="modal-content px-6 py-5 text-left"
        data-testid="modal-content"
      >
        <!--Title-->
        <div class="mb-4 mt-0 flex items-center justify-between">
          <h1 class="my-0 text-xl font-bold">{{ title }}</h1>
          <button
            data-testid="modal-close-button"
            type="button"
            @click="closeModal"
            title="Close"
            class="modal-close z-50 cursor-pointer"
            v-if="!doNotClose"
          >
            <font-awesome-icon
              class="text-black"
              :icon="['far', 'times']"
            ></font-awesome-icon>
          </button>
        </div>
        <!--Body-->
        <div class="text-sm text-grey-600">
          <slot name="body" />
        </div>
        <!--Footer-->
        <div class="mt-4 flex justify-end" v-if="showFooter">
          <TwoButton
            name="ok"
            data-testid="ok-button-modal"
            @click="closeModal"
            class="btn-primary"
            v-if="showOkButton"
          >
            {{ okButtonText }}
          </TwoButton>
          <TwoButton
            name="cancel"
            data-testid="cancel-button-modal"
            @click="closeModal"
            v-else-if="showCancelButton"
          >
            Cancel
          </TwoButton>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { TwoButton } from "@wegift/two-components";

export default defineComponent({
  components: { TwoButton },
  props: {
    title: { type: String, default: "" },
    show: { type: Boolean, default: false },
    showCancelButton: { type: Boolean, default: false },
    showOkButton: { type: Boolean, default: false },
    okButtonText: { type: String, default: "Ok" },
    containerClass: { type: String },
    doNotClose: { type: Boolean, default: false },
  },
  emits: ["update:show", "close"],
  setup(_, { emit }) {
    const closeModal = () => {
      emit("close", false);
      emit("update:show", false);
    };
    return { closeModal };
  },
  computed: {
    showFooter() {
      const footerSlotUsed = !!this.$slots.footer;
      return this.showCancelButton || this.showOkButton || footerSlotUsed;
    },
  },
});
</script>
<style lang="sass" scoped>
.animated
  -webkit-animation-duration: 500ms
  animation-duration: 500ms
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

.fadeIn
  animation-name: fadeIn

.fadeOut
  animation-name: fadeOut

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

@keyframes fadeOut
  from
    opacity: 1
  to
    opacity: 0
</style>
